import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-remarkable'

import './services.scss'
import ContactBanner from '../contactBanner'

const ServiceDescription = ({ description, items }) => (
  <div className='service-description-section'>
    <Markdown options={{ html: true }}>
      {description}
    </Markdown>
    {items.map((item)  => {
      switch (item.__typename) {
        case 'ContentfulContactBanner':
          return <ContactBanner description={item.description} />
        default:
          return null
      }
    })}
  </div>
)

ServiceDescription.propTypes = {
  description:PropTypes.string,
  items: PropTypes.array,
}

export default ServiceDescription
