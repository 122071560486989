import React from 'react'
import PropTypes from 'prop-types'
import ColumnLayout from 'react-column-layout'
import Markdown from 'react-remarkable'

import Lightbox from 'lightbox-react'
import 'lightbox-react/style.css'

import Button from '../button'
import './grid.scss'

class PhotoGrid extends React.Component {
  constructor() {
    super()
    this.state = { amount: 10, isLightboxOpen: false, photoIndex: 0 }
  }

  loadMore = () => {
    const { amount } = this.state
    this.setState({ amount: amount + 10 })
  }

  getPhotos = (thumbnail = true) => {
    const { photos } = this.props
    return photos.map((photo, index) => ({
      index,
      thumbnailSrc: thumbnail ? photo.sizes.src : photo.file.url,
      thumbnailWidth: photo.resize.width,
      thumbnailHeight: photo.resize.height,
    })).slice(0, this.state.amount)
  }

  getPhotosSize = () => {
    const { photos } = this.props
    return photos.length
  }

  handlePhotoClick = (e, photo) => {
    this.setState({ isLightboxOpen: true, photoIndex: photo.index })
  }

  render() {
    const { header } = this.props
    const { amount, isLightboxOpen, photoIndex } = this.state

    const photos = this.getPhotos(false).map((photo) => photo.thumbnailSrc)
    const thumbnails =  this.getPhotos(true)

    return (
      <div className='photo-grid-container'>
        {header && (
          <h3 className='photo-grid-header'>
            <Markdown options={{ html: true }}>
              {header}
            </Markdown>
          </h3>)
        }
        <ColumnLayout
          columnMinWidth={300}
          margin={0}
          gutter={0}
          photos={thumbnails}
          clickHandler={this.handlePhotoClick}
        />
        {this.getPhotosSize() > amount
          && <div className='button-bar'><Button isLink type='button' onClick={this.loadMore}>Más fotos</Button></div>
        }
        {isLightboxOpen && (
          <Lightbox
            mainSrc={photos[photoIndex]}
            nextSrc={photos[(photoIndex + 1) % photos.length]}
            prevSrc={photos[(photoIndex + photos.length - 1) % photos.length]}
            onCloseRequest={() => this.setState({ isLightboxOpen: false })}
            onMovePrevRequest={() => {
              this.setState({
                photoIndex: (photoIndex + photos.length - 1) % photos.length,
              })
            }}
            onMoveNextRequest={() => {
              this.setState({
                photoIndex: (photoIndex + 1) % photos.length,
              })
            }}
          />
        )}
      </div>
    )
  }
}

PhotoGrid.defaultProps = {
  photos: [],
}

PhotoGrid.propTypes = {
  photos: PropTypes.array,
  header: PropTypes.string,
}

export default PhotoGrid
