import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../layouts'
import PhotoGrid from '../components/photoGrid'
import ServicesDescription from '../components/services/serviceDescription'
import HeroSection from '../components/hero'
import ServicesSection from '../components/services'
import Contact from '../components/contact'
import ContactBanner from '../components/contactBanner'
import InstagramSection from '../components/instagramSection'
import QuoteBanner from '../components/quoteBanner'
import TextSection from '../components/textSection'
import { commonTags } from '../config/constants'

const TemplatePage = ({ data }) => {
  const { page } = data
  const {
    ogTitle, ogKeywords, ogDescription, ogImage, favIco, pageSections, heroImage,
  } = page

  const metadata = data.siteMetadata.edges[0].node
  if (!metadata) { return null }

  return (
    <Layout>
      <div className='page-container'>
        <Helmet
          title={ogTitle}
          meta={commonTags(ogTitle, ogDescription, ogKeywords, ogImage)}
        >
          <link rel='icon' type='image/png' href={favIco.file.url}  />
        </Helmet>
        {pageSections.map((pageSection) => {
          const pageSectionType = pageSection.__typename
          if (pageSectionType === 'ContentfulQuoteBanner') {
            return (
              <QuoteBanner
                phrase={pageSection.description && pageSection.description.description}
                author={pageSection.author}
              />
            )
          }

          if (pageSectionType === 'ContentfulFotoAlbum') {
            return (
              <PhotoGrid header={pageSection.header && pageSection.header.header} photos={pageSection.photos || []} />
            )
          }

          if (pageSectionType === 'ContentfulTextSection') {
            const {
              title, description, image, imagePosition,
            } = pageSection
            return (
              <div className='section-container'>
                <TextSection
                  imagePosition={imagePosition}
                  title={title}
                  description={description}
                  image={image}
                  className='page-content-text-section'
                />
              </div>
            )
          }

          if (pageSectionType === 'ContentfulPageSection') {
            switch (pageSection.name) {
              case 'Service Page Description': {
                return (
                  <ServicesDescription
                    items={pageSection.items}
                    description={pageSection.description && pageSection.description.description}
                  />
                )
              }
              case 'Bodas Hero':
              case 'Marca Editorial Hero':
              case 'Familia Hero':
              case 'Embarazos Hero':
              case 'Parejas Hero':
              case 'Personales Hero':
              case 'About Hero':
                return (
                  <HeroSection
                    title={pageSection.title}
                    image={heroImage}
                    items={pageSection.items}
                    metadata={metadata}
                  />
                )
              case 'Services Section':
                return (
                  <ServicesSection
                    items={pageSection.items}
                    title={pageSection.title}
                    description={pageSection.description}
                  />)
              case 'Contact Banner':
                return (
                  <ContactBanner
                    description={pageSection.description}
                  />)
              case 'Contact Form':
                return (
                  <Contact
                    title={pageSection.title}
                    description={pageSection.description && pageSection.description.description}
                  />)
              case 'Instagram Section':
                return (
                  <InstagramSection
                    description={pageSection.description && pageSection.description.description}
                    metadata={metadata}
                  />
                )
              default:
                return null
            }
          }
          return null
        })}
      </div>
    </Layout>
  )
}

export const pageDataQuery = graphql`
  query pageDataTemplateQuery($slug: String!) {
    siteMetadata: allContentfulSiteMetadata {
      edges {
        node {
          telephone
          instagram
          email
          behance
        }
      }
    }
    page: contentfulPage(slug: { eq: $slug }) {
      title
      ogTitle
      ogDescription
      ogKeywords
      heroImage {
        description
        file {
          url
        }
      }
      ogImage {
        description
        file {
          url
          contentType
          details {
            size
            image {
              width
              height
            }
          }
        }
      }
      pageSections {
        ... on ContentfulQuoteBanner {
          ... QuoteFragment
        }
        ... on ContentfulPageSection {
          ...SectionFragment
        }
        ... on ContentfulFotoAlbum {
          ...PhotoAlbumFragment
        }
        ... on ContentfulTextSection {
          ... TextSectionFragment
        }
      }
      favIco {
        file {
          url
        }
      }
    }
  }
`

TemplatePage.propTypes = {
  data: PropTypes.object,
}

export default TemplatePage
